module.exports = {
	"common": {
		"channel": "Channel",
		"merchant": "Merchant",
		"required": "Required",
		"show": "Show",
		"open": "Open",
		"amount": "Amount",
		"currency": "Currency",
		"countries": "Countries",
		"comma_separated": "comma separated",
		"save": "Save",
		"add": "Add",
		"create": "Create",
		"create_breadcrumb": "Creation",
		"edit": "Edit",
		"delete": "Delete",
		"cancel": "Cancel",
		"on": "On",
		"off": "Off",
		"follow": "Follow",
		"reset": "Reset",
		"total": "Total",
		"month": "Month",
		"week": "Week",
		"day": "Day",
		"hours": "Hours",
		"short_day": "d",
		"short_hour": "h",
		"short_minute": "m",
		"short_second": "s",
		"other_platforms": "Other platforms",
		"default": "By default",
		"country": "Country",
		"fee": "Fee",
		"deposit": "Deposit",
		"deposits": "Deposits",
		"deposit_types": {
			"normal": "Normal",
			"one_click": "1-click",
			"paynplay": "Pay N Play"
		},
		"withdraw": "Withdraw",
		"withdraws": "Withdraws",
		"withdraw_types": {
			"auto": "Auto",
			"manual": "Manual",
			"semiauto": "Semiauto"
		},
		"project": "Project",
		"locale": "Locale",
		"jurisdiction": "Jurisdiction",
		"jurisdictions": "Jurisdictions",
		"mode": "Mode",
		"search": "Search",
		"mobile_version": "Mobile version",
		"is_landscape": "Landscape mode",
		"submit": "Submit",
		"from": "From",
		"to": "To",
		"yes": "Yes",
		"no": "No",
		"tag": "Tag",
		"typing_here": "Typing here",
		"not_selected": "Not selected",
		"nothing_found": "Nothing found",
		"invalid_url": "Ivalid URL",
		"unknown_error": "Unknown error",
		"order": "Order",
		"scope": "Scope",
		"card": "Card",
		"session": "Session",
		"system": "System",
		"customer": "Customer",
		"user": "User",
		"bank": "Bank",
		"processor": "Processor",
		"processor_account": "Processor account",
		"account_balance": "Balance",
		"order_failure_code": "Order failure code",
		"success": "Success",
		"error": "Error",
		"device": "Device",
		"browser": "Browser",
		"platform": "Platform",
		"user_agent": "User agent",
		"can_process": "Process?",
		"back": "Back",
		"close": "Close",
		"datepicker_placeholder": "Select date",
		"copied": "Copied",
		"enabled": "Enabled",
		"actions": "Actions",
		"reconciliation": "Reconciliation",
		"alert": "Alert",
		"period_types": {
			"created_at": "By created time",
			"status_updated_at": "By status updated time",
			"processed_at": "By processed time"
		},
		"sort_types": {
			"period": "By period",
			"amount": "By amount"
		},
		"payment_status": {
			"initial": "Initial",
			"paid": "Paid",
			"success": "Success",
			"requested": "Requested",
			"pending": "Pending",
			"failed": "Failed",
			"canceled": "Canceled",
			"rejected": "Rejected",
			"scheduled": "Scheduled",
			"credited": "Credited",
			"refunded": "Refunded",
			"error": "Error"
		},
		"fee_types": {
			"customer_fee": "Our fee",
			"personal_fee": "Personal fee",
			"project_fee": "Project fee"
		},
		"title": "Title",
		"filters": "Filters",
		"settings": "Settings",
		"conditions": "Conditions",
		"main": "Main",
		"information": "Information",
		"other": "Other",
		"grouping": {
			"title": "Grouping",
			"other": "Other",
			"period": {
				"title": "By period",
				"days": "Days",
				"week": "Week",
				"month": "Month",
				"year": "Year"
			},
			"project": "By project",
			"by_processor": "By processor",
			"processor": "Processor",
			"processor_with_subprocessor": "Processor with subprocessor",
			"by_system": "By PS",
			"system": "PS",
			"system_with_subsystem": "PS with subsystem",
			"customer_country": "By customer country",
			"token_country": "By country by IP",
			"type": "By type",
			"locale": "By locale",
			"currency": "By currency",
			"fee_currency": "By fee currency",
			"order_currency": "By order currency",
			"jurisdiction": "By jurisdiction",
			"bank": "By bank",
			"platform": "By platform",
			"report_currency": "Report currency",
			"billing_currency": "By billing currency",
			"user": "By user",
			"customer_area": "By customer area",
			"token_area": "By IP area",
			"processor_channel": "By processor channel",
			"widget_mode": "By widget mode",
			"billing_type": "By billing type",
			"ftd": "By FTD",
			"refcode": "By refcode",
			"purse_country": "By purse country",
			"failure_type": "By failure type",
			"failure_subtype": "By failure subtype",
			"failure_code": "By failure code",
			"failure_message": "By failure message",
			"vwo_variation": "By VWO Variation",
			"card_type": "By card type"
		},
		"continents": {
			"AF": "Africa",
			"AS": "Asia",
			"EU": "Europe",
			"NA": "North America",
			"OC": "Oceania",
			"SA": "South America"
		},
		"billing_types": {
			"normal": "Normal",
			"free": "Free",
			"test": "Test",
			"internal": "Internal"
		},
		"country_areas": {
			"ru": "Russian Federation",
			"cis": "CIS",
			"euro_union": "Europe (EU)",
			"not_euro_union": "Europe (Non EU)",
			"west_asia": "West Asia",
			"east_asia": "East Asia",
			"africa": "Africa",
			"australia_and_oceania": "Australia and Oceania",
			"south_america": "South America",
			"north_america": "North America",
			"other": "Others"
		},
		"refund_modes": {
			"full": "Full",
			"partial": "Partial",
			"disabled": "Disabled"
		},
		"payment_loop_types": {
			"brief": {
				"open": "Non-closed",
				"closed": "Closed",
				"strictly_closed": "Strictly closed"
			},
			"full": {
				"open": "Non-closed",
				"closed": "Closed (Deposit is required)",
				"strictly_closed": "Strictly closed (Purse is required)"
			}
		},
		"badge": {
			"enabled": "Enabled",
			"disabled": "Disabled"
		},
		"saved_purse": "Saved purse",
		"description": "Description",
		"rule_segment": "Rule segment",
		"rule_action": "Rule action",
		"rule": "Rule",
		"ok": "OK",
		"platform_type": {
			"mobile": "Mobile",
			"desktop": "Desktop",
			"other": "Other"
		},
		"status": "Status",
		"presets": {
			"title": "Selected preset",
			"new": "+ New preset",
			"new_preset_title": "Preset title",
			"enter_title": "Enter the preset title",
			"add": "Add",
			"cancel": "Cancel",
			"delete": "Delete",
			"update": "Save",
			"delete_confirmation": "Delete preset \"{title}\"?",
			"preset_updated": "Preset updated"
		},
		"options": "Options",
		"monitor_volume": "Monitor volume"
	}
};